<template>
  <div class="rule">
    <img class="back" src="../../assets/img/back2.jpeg" alt="">
    <div class="logo-title">
      <img class="logo" src="../../assets/img/logo1.png" alt="">
      <p>千秋宏图（河北雄安）酒业有限公司</p>
    </div>
    <div class="brief">
      <p>公司简介：<span>千秋宏图 (河北雄安) 酒业有限公司，成立于2023年，位于河北省雄安新区，是一家以从事酒、饮料和精制茶制造业为主的企业；是川酒集团“一城一品”项目的核心合作伙伴；是千秋宏图酒的全国唯一运营商。千秋宏图（河北雄安）酒业有限公司旨在成为“中国雄安白酒全产业链综合服务商，酿老百姓喝得起的纯粮好酒”。经营范围许可项目：烟草制品零售；酒类经营；食品销售；广告制作；品牌管理；货物进出口；酒店管理等。</span></p>
    </div>
  </div>
</template>

<script>
import {getDealList} from "../../api/banner";
import {getToken, setToken} from "../../utils/auth";
import {wxlogin} from "../../api/login";

export default {
  name: "Protocol",
  data() {
    return {
      title:'协议',
      isBack: true,
      ruleData:{}
    }
  },
  created() {
    // this.getWechatCode()
  },
  methods:{
    async getWechatCode() {
      let appid = 'wx68e55df061305e7a'         //公众号的APPID
      // let appSecret = 'af12444f6c2462eeeaa669fd1a834ccd'
      // let data = this.getUrlCode(); //是否存在code
      console.log(this.$route.query)
      let data = this.$route.query   // 获取链接参数
      if (data.state == null || data.state === "") {
        //不存在就打开上面的地址进行授权
        window.location.href =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
            appid +
            "&redirect_uri=" +
            encodeURIComponent(window.location.href) +
            "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
      }else {
        if(!getToken('Admin-Token')){
          let res = await wxlogin({code:data.code})
          setToken(res.token)
        }
        this.getDealList()
      }
      // this.getPlacardList()
    },
    getDealList(){
      getDealList().then(res => {
        console.log(res)
        this.ruleData = res.data[1]
      })
    }
  }
}
</script>

<style scoped>
.rule{
  padding: 20px;
}

.back{
  left: 0;
  right: 0;
  top: 0;
  bottom: 50px;
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.logo-title{
  margin-top: 320px;
  width: 100%;
  text-align: center;
}

.logo-title .logo {
  width: 150px;
  height: 60px;
}

.logo-title p{
  font-size: 18px;
  font-weight: bold;
  font-family: 宋体;
  margin-top: 20px;
  color: #ffffff;
}

.brief {
  width: 95%;
  margin: 30px auto;
  display: flex;
}

.brief p{
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  font-family: 宋体;
}

.brief p span {
  font-size: 14px;
  color: #f8f8f8;
  font-family: 宋体;
  line-height: 2em;
  text-align: justify;
}
</style>
