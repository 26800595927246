import request from '@/utils/request'

/**
 * 轮播图
 * @param query
 * @returns {*}
 */
// 查询参数列表
export function getBannerList(query) {
    return request({
        url: '/user/banner/getCarouselList',
        method: 'get',
        params: query
    })
}

/**
 * 海报图
 * @param query
 * @returns {*}
 */
export function getPlacardList(query) {
    return request({
        url: '/user/banner/getPlacardList',
        method: 'get',
        params: query
    })
}

export function getDealList(params){
    return request({
        url:'/user/banner/getDealList',
        method:'get',
        params
    })
}
