<template>
  <div>
    <!--       轮播图-->
    <!-- lu轮播图-------- -->
    <div class="lunbo">
      <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white">
        <van-swipe-item v-for="item in bannerList" :key="item.id">
          <img :src="item" alt="图"/> </van-swipe-item>
      </van-swipe>
    </div>
    <!--      详细信息-->
    <div class="detail">
      <p class="title">{{detail.goodsName}}</p>
      <div class="time">
        <p>{{ parseTime(detail.createTime) }}</p>
        <p>{{detail.goodsInventory}}库存</p>
      </div>
      <div class="content">
        <div class="content-item">
          <img src="../../assets/img/share.png" alt="">
          <p>{{detail.goodsInfo.split(',')[0]}}</p>
        </div>
      </div>
      <div class="desc">
        <img src="../../assets/img/yinhao.png" alt="">
        <p>  {{detail.goodsInfo}}</p>
      </div>
    </div>
    <div class="line"></div>
<!--    <div class="bottomChat">-->
<!--      <div class="share-content">-->

<!--        <div class="phoneBtn" @click="callPhone(detail.userPhone)">-->
<!--          联系电话-->
<!--        </div>-->
<!--        <div class="phoneBtn1" v-if="detail.isApplyFor==2" @click="adopt(detail.id)">-->
<!--          领养-->
<!--        </div>-->
<!--        <div class="phoneBtn1" v-if="detail.isApplyFor==1" >-->
<!--          申请中-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import {getInfo} from "../../api/pet";

export default {
  name: "GoodsDetail",
  data(){
    return{
      title: "产品信息",
      isBack: true,
      detail:{},
      bannerList: [
        { id: '1', url: 'https://duole-1323105563.cos.ap-beijing.myqcloud.com/duole/50111707015263838.jpg' },
        { id: '2', url: 'https://duole-1323105563.cos.ap-beijing.myqcloud.com/duole/40211707015325103.jpg' },
        { id: '3', url: 'https://duole-1323105563.cos.ap-beijing.myqcloud.com/duole/35801707015349305.jpg' }
      ],
      Products: [
        { id: '1', url: 'https://duole-1323105563.cos.ap-beijing.myqcloud.com/duole/7551706952289546.png', title: '五粮液' },
        { id: '2', url: 'https://duole-1323105563.cos.ap-beijing.myqcloud.com/duole/57271706952377654.png', title: '西凤酒' },
        { id: '3', url: 'https://duole-1323105563.cos.ap-beijing.myqcloud.com/duole/95531706952406191.png', title: '泸州老窖' },
        { id: '4', url: 'https://duole-1323105563.cos.ap-beijing.myqcloud.com/duole/82091706952440426.png', title: '郎酒系列' },
        { id: '5', url: 'https://duole-1323105563.cos.ap-beijing.myqcloud.com/duole/82091706952440426.png', title: '郎酒系列' },
        { id: '6', url: 'https://duole-1323105563.cos.ap-beijing.myqcloud.com/duole/82091706952440426.png', title: '郎酒系列' },
        { id: '7', url: 'https://duole-1323105563.cos.ap-beijing.myqcloud.com/duole/82091706952440426.png', title: '郎酒系列' },
        { id: '8', url: 'https://duole-1323105563.cos.ap-beijing.myqcloud.com/duole/82091706952440426.png', title: '郎酒系列' },
      ]
    }
  },
  created() {
    console.log('22222222222',this.$route)
    this.getInfo()
  },
  methods:{
    getInfo(){
      let id = this.$route.query.id
      getInfo(id).then(res => {
        this.bannerList = res.data.goodsUrl.split(',')
        this.detail = res.data
      })
    }
  }
}
</script>

<style scoped>
p,div{
  margin: 0;
  padding: 0;
}
/*轮播*/
.lunbo {
  /* background: #c52519; */
}
.my-swipe {
  margin: 0 auto 0;
  width: 100%;
  height: 350px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  background-color: #ffffff;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.my-swipe .van-swipe-item img {
  width: 70%;
  height: 75%;
}
/*-----------轮播图结束------------*/
/*详细信息*/
.detail{
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  position: relative;
  background-color: #ffffff;
  top: -30px;
  padding: 15px;
}
.title{
  font-size: 22px;
  color: #363636;
  font-weight: bold;

}
.time{
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  color: #969696;
  justify-content: space-between;
}
.content{
  margin:15px 0;
  background-color: #f7f7f7;
  border-radius: 10px;
  color: #969696;
  padding: 10px 10px 0 10px;

}
.content-item{
  display: flex;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 10px;
}
.content-item img{
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.desc{
  font-size: 14px;
  height: 200px;
  color: #333;
}
.desc img{
  width: 18px;
  height: 18px;

}
.line{
  width: 100%;
  height: 6px;
  background-color: #f7f7f7;
}
/* 底部联系方式*/
.bottomChat{
  border-top-color: #969696;
  border-top-width: 1px;
  border-top-style: solid;
  background-color: #ffffff;
  height: 70px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

}
.share-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.share{
  display: flex;
  margin-top: 10px;
}
.share img{
  width: 22px;
  height: 22px;
  margin-left: 10px;
  margin-right: 5px;
}
.phoneBtn{
  margin-top: 10px;
  background-color: #fa993b;
  width: 160px;
  height: 35px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #ffffff;
}
.phoneBtn1{
  margin-top: 10px;
  background-color: #67c23a;
  width: 160px;
  height: 35px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #ffffff;
}
</style>
