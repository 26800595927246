<template>
  <div class="record-back">
    <!-- 背景图 -->
    <img class="back" src="../../assets/img/back2.jpeg" alt="">
    <div class="integral">
      <img src="../../assets/img/back-amount.png" alt="">
      <span class="title">我的积分</span>
      <span class="int">{{integral}}</span>
    </div>
    <div class="box">
      <van-tabs v-model="active"
                @click="onChange"
                title-active-color="#E13D1C"
                background="#FEF2D3">
        <van-tab title="红包记录">
<!--          <div class="title">红包记录</div>-->
          <div class="record-item" v-if="recordsList.length != 0" v-for="(item,index) in recordsList" :key="index">
            <div class="img">
              <img src="../../assets/img/logo.png" alt="">
            </div>
            <div class="record-content">
              <span>{{ item.arrivedInfo }}</span>
              <span>{{ parseTime(item.createTime,'{m}-{d} {h}:{i}') }}</span>
            </div>
            <span class="cash">+{{ item.arrivedAmount }}</span>
          </div>
          <div class="null" v-if="recordsList.length == 0">
            <img src="../../assets/img/null.png" alt="">
            <p> 暂无领取记录~ </p>
          </div>
        </van-tab>
        <van-tab title="积分记录">
          <!--          <div class="title">红包记录</div>-->
          <div class="record-item" v-if="integralList.length != 0" v-for="(item,index) in integralList" :key="index">
            <div class="img">
              <img src="../../assets/img/logo.png" alt="">
            </div>
            <div class="record-content">
              <span>{{ item.info }}</span>
              <span>{{ parseTime(item.createTime,'{m}-{d} {h}:{i}') }}</span>
            </div>
            <span class="cash">+{{ item.detail }}</span>
          </div>
          <div class="null" v-if="integralList.length == 0">
            <img src="../../assets/img/null.png" alt="">
            <p> 暂无积分记录~ </p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import {IntegralList, recordsList} from "../../api/pet";
import {getToken, setToken} from "../../utils/auth";
import {wxlogin} from "../../api/login";

export default {
  name: "record",
  data(){
    return {
      title: '到账记录',
      isBack: true,
      active:0,
      recordsList:[],
      integralList: [],
      integral:0,
    }
  },
  created() {
    this.getWechatCode()
  },
  methods:{
    onChange(){},
    async getWechatCode() {
      let appid = 'wx68e55df061305e7a'         //公众号的APPID
      // let appSecret = 'af12444f6c2462eeeaa669fd1a834ccd'
      // let data = this.getUrlCode(); //是否存在code
      console.log(this.$route.query)
      let data = this.$route.query   // 获取链接参数
      if (data.state == null || data.state === "") {
        //不存在就打开上面的地址进行授权
        window.location.href =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
            appid +
            "&redirect_uri=" +
            encodeURIComponent(window.location.href) +
            "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
      }else {
        if(!getToken('Admin-Token')){
          let res = await wxlogin({code:data.code})
          setToken(res.token)
        }
        this.getRecordsList()
        this.getIntegral()
      }
      // this.getPlacardList()
    },
    // 红包到账记录
    getRecordsList(){
      recordsList().then(res => {
        this.recordsList = res.rows
      })
    },
    // 积分记录
    getIntegral(){
      this.integral = 0
      IntegralList({pageNum:1,pageSize:99999}).then(res => {
        this.integralList = res.rows
        res.rows.forEach(item => {
          this.integral += Number(item.detail)
        })
        this.integral = this.integral.toFixed(2)
      })
    }
  }
}
</script>

<style scoped>

.record-back{
  background: linear-gradient(to bottom, #df3815,#ed583a);
  padding-bottom: 20px;
}

.integral {
  margin: 340px 25px 25px 25px;
  /*width: 100%;*/
  height: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.integral img{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index:0;
}

.integral .title{
  font-size: 18px;
  color: #E13D1C;
  position: absolute;
  z-index: 22;
  top: 50px;
}

.int {
  font-size: 42px;
  color: #E13D1C;
  font-weight: 700;
  margin-top: 30px;
  position: absolute;
  z-index: 22;
}

.box{
  margin: 20px 25px 25px 25px;
  padding: 20px;
  background: #fff2d2;
  border-radius: 30px;
  /*position: absolute;*/
  /*z-index: 22;*/
  /*left: 0;*/
  /*right: 0;*/
}

.box .title{
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  color: #8D4F42;
}

.back{
  left: 0;
  right: 0;
  top: 0;
  bottom: 50px;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  /*background: #e74b2c;*/
  /*background-image: url('https://xaqqht-1323926769.cos.ap-beijing.myqcloud.com/xaqqht/back.png');*/
}

.box .record-item{
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
}

.box .record-item .img{
  width: 60px;
  height: 60px;
  border-radius: 50px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FBE3A4;
}
.box .record-item .img img {
  width: 20px;
  height: 30px;
}

.box .record-item .record-content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.box .record-item .record-content span:nth-child(1){
  font-size: 18px;
  font-weight: 600;
  color: #8D4F42;
}
.box .record-item .record-content span:nth-child(2){
  font-size: 14px;
  color: #8D4F42;
  margin-top: 6px;
}

.box .record-item .cash{
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #8D4F42;
}

.null{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.null p{
  margin-top: 20px;
  color: #8D4F42;
}
</style>
