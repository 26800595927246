import request from '@/utils/request'

/**
 * 宠物信息
 * @param query
 * @returns {*}
 */

// 查询参数列表
export function getGoodsList(query) {
    return request({
        url: '/user/goods/getList',
        method: 'get',
        params: query
    })
}
// 查询参数列表
export function getInfo(id) {
    return request({
        url: `/user/goods/getInfo/${id}`,
        method: 'get',
    })
}

/**
 * 红包到账记录
 * @returns {*}
 */
export function recordsList(query){
    return request({
        url:'/user/records/list',
        method:'get',
        params: query
    })
}

/**
 * 积分记录
 * @param params
 * @returns {*}
 * @constructor
 */
export function IntegralList(params) {
    return request({
        url:'/user/points/list',
        method:'get',
        params
    })
}
