<template>
  <div class="hong-back"
       :style="`height:${1000 + (300 * goodsList.length > 100 * recordsList.length ? 300 * goodsList.length : 100 * recordsList.length ) }px`">

    <!-- 背景图 -->
    <img class="back" src="../../assets/img/back.png" alt="">

    <!-- 红包开启之前 -->
    <div class="before" v-if="beforeShow && redEnvelope.hbIsReceive != 3" >
      <img class="before-img" @click="clickOpen" src="../../assets/img/1.png" alt="">
      <div class="wechat">
        <img src="../../assets/img/wechat.png" alt="">
        <p>可提现至微信</p>
      </div>
    </div>

    <!-- 红包开启之后 -->
    <div class="after" v-if="afterShow" >
      <div class="receive" v-if="redEnvelope.hbIsReceive == 1">
        <span class="mini-title">亲，您已经</span>
        <span class="mini-title">领取过红包了哟~</span>
      </div>
      <div class="receive" v-else-if="redEnvelope.hbIsReceive == 2">
        <span class="mini-title">亲，该红包</span>
        <span class="mini-title">已经被领取过了哟~</span>
      </div>
      <div class="receive" v-else>
        <span class="mini-title" v-if="packData.hbAmount == 0">很遗憾</span>
        <span class="mini-title" v-if="packData.hbAmount == 0">您此次并未中奖!!!</span>
        <span class="title" v-if="packData.hbAmount != 0" >已领取</span>
        <span class="amount" v-if="packData.hbAmount != 0">{{ packData.hbAmount }} 元</span>
      </div>
      <img class="before-img" v-if="afterShow" src="../../assets/img/2.png" alt="">
      <div class="wechat">
        <img src="../../assets/img/wechat.png" alt="">
        <p>可提现至微信</p>
      </div>
    </div>

    <!--  红包规则  -->
    <div class="rule" @click="clickProtocol">
      <img src="../../assets/img/rule.png" alt="">
    </div>

    <!--  奖励明细  -->
    <div class="reward" :style="redEnvelope.hbIsReceive == 3 ?'top:50%':'top: 95%;'">
      <div class="amount">
        <div class="btn" @click="popupShow = true">更多内容关注公众号</div>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="item in bannerList" :key="item.id">
            <img :src="item.url" alt="图"/> </van-swipe-item>
        </van-swipe>
      </div>

      <!--   产品信息   -->
      <div class="record">
        <van-tabs v-model="active"
                  @click="onChange"
                  title-active-color="#E13D1C"
                  background="#FEF2D3">
          <van-tab title="赚钱攻略">
            <div  v-for="(item,index) in goodsList" :key="index">
              <div class="item" v-if="index%2 == 0">
                <img class="logo" src="../../assets/img/logo1.png" alt="">
                <img :src="item.goodsUrl" alt="">
                <div class="item-con">
                  <span>{{ item.goodsName }}</span>
                  <span>{{ item.goodsInfo[0] }}</span>
                  <span>{{ item.goodsInfo[1] }}</span>
                  <span>{{ item.goodsInfo[2] }}</span>
                </div>
              </div>
              <div class="item2" v-if="index%2 != 0">
                <div class="item-con">
                  <span>{{ item.goodsName }}</span>
                  <span>{{ item.goodsInfo[0] }}</span>
                  <span>{{ item.goodsInfo[1] }}</span>
                  <span>{{ item.goodsInfo[2] }}</span>
                </div>
                <img :src="item.goodsUrl" alt="">
                <img class="logo" src="../../assets/img/logo1.png" alt="">
              </div>
            </div>
            <div class="step">
              <img src="../../assets/img/step.png" alt="">
              <div class="steps-item">
                <span>揭盖扫码</span>
                <span>领取红包</span>
                <span>直接到账</span>
              </div>
            </div>
          </van-tab>
          <van-tab title="红包记录">
            <div class="box">
              <div class="record-item" v-if="recordsList.length != 0" v-for="(item,index) in recordsList" :key="index">
                <div class="img">
                  <img src="../../assets/img/logo.png" alt="">
                </div>
                <div class="record-content">
                  <span>{{ item.arrivedInfo }}</span>
                  <span>{{ parseTime(item.createTime,'{m}-{d} {h}:{i}') }}</span>
                </div>
                <span class="cash">+{{ item.arrivedAmount }}</span>
              </div>
              <div class="null" v-if="recordsList.length == 0">
                <img src="../../assets/img/null.png" alt="">
                <p> 暂无领取记录~ </p>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>

    <!--  红包开启过程  -->
    <div class="bg" v-if="show2" @click="displaytrue">
      <div>
        <div class="content" v-if="show1">
          <img class="img" src="../../assets/img/redbag1.gif" />
        </div>
        <div class="content" v-if="show3" >
          <div class="receive">
            <span class="title">已领取</span>
            <span class="amount">{{ packData.hbAmount }} 元</span>
          </div>
          <img class="img" src="../../assets/img/2.png" alt="">
        </div>
      </div>
    </div>

    <!--  海报图弹出层  -->
    <div class="popup" v-if="popupShow" @click="popupShow = false">
      <van-swipe class="poster-swipe" :autoplay="3000"
                 @change="onPopupChange"
                 indicator-color="white">
        <van-swipe-item v-for="item in posterList" :key="item.id">
          <img :src="item.url" alt="图"/>
        </van-swipe-item>
      </van-swipe>
      <p style="color: #ffffff"> 亲，长按图片即可保存到手机哦~ </p>
    </div>

    <!--  协议弹窗  -->
    <van-popup v-model="ruleShow" position="bottom" round closeable :style="{ height: '90%' }">
      <div style="padding: 20px">
        <div style="padding: 20px 0"><p style="text-align: center">{{ ruleData.name }}</p></div>
        <div v-html="ruleData.context"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  redPacketcreate,
  redPacketdrawRedPacket
} from '../../api/system/module'
import {getGoodsList, recordsList} from "../../api/pet";
import {getBannerList, getDealList, getPlacardList} from "../../api/banner";
import {wxlogin} from "../../api/login";
import {setToken,getToken} from '../../utils/auth'

export default {
  data () {
    return {
      title: "",
      isBack: true,
      // 倒计时
      time: 30 * 60 * 60 * 1000,
      beforeShow: false,   //红包开始
      afterShow: false,    // 红红包打开
      popupShow: false,  // 海报图
      ruleShow: false,  // 协议
      ruleData: {},
      value: "",
      user: {},
      goodsList: [],
      recordsList: [],
      bannerList:[],  // 轮播图
      posterList: [
        {url:'https://xaqqht-1323926769.cos.ap-beijing.myqcloud.com/xaqqht/1710916657270.png'},
        {url:'https://xaqqht-1323926769.cos.ap-beijing.myqcloud.com/xaqqht/1710916658844.png'},
      ],  // 海报图
      currentIndex: 0,
      show: false,
      show1: false,
      show2: false,
      show3:false,
      amountShow: true,
      code: '',
      wxCode:'',
      redEnvelope: {},
      packData:{},
      active: 0,
      query:""
    }
  },
  created () {
    this.getWechatCode()
  },
  methods: {
    onChange(){},
    //登录跳转
    login () {
      this.$router.replace({ name: "Login" })
    },
    obtain (code){
      redPacketcreate({code:code}).then(res => {
        if(res.data.hbIsReceive == 3){
          this.$toast.fail('该二维码无效')
          this.beforeShow = false
          this.redEnvelope = res.data
          return
        }
        if(res.data.hbIsReceive == 1 || res.data.hbIsReceive == 2){
          this.beforeShow = false
          this.afterShow = true
        }else {
          this.beforeShow = true
          this.afterShow = false
        }
        this.redEnvelope = res.data
      })
    },
    // 返回首页
    clickHome(){
      this.$router.push({name:'Index'})
    },
    // 协议信息
    clickProtocol(){
      this.ruleShow = true
    },
    getDealList(){
      getDealList().then(res => {
        console.log(res)
        this.ruleData = res.data[0]
      })
    },
    // 获取产品信息
    getGoodsList(){
      getGoodsList().then(res => {
        res.rows.forEach(item => {
          item.goodsInfo = item.goodsInfo.split(',')
        })
        this.goodsList = res.rows.slice(0,3)
      })
    },
    // 红包到账记录
    getRecordsList(){
      recordsList().then(res => {
        this.recordsList = res.rows
      })
    },
    // 点击领取红红包
    clickOpen(){
      // this.beforeShow = false
      this.show2 = true
      this.show1 = true
      setTimeout(() => {
        this.amountShow = false
        redPacketdrawRedPacket({code:this.code}).then(res => {
          console.log(res)
          this.show3 = true
          this.show = false
          this.show1 = false
          this.packData = res.data
          this.getRecordsList()
        }).catch(err => {
          this.show2 = false
          this.show1 = false
        })
      },500)
      setTimeout(() => {
        this.show3 = true
        this.show = false
        this.show1 = false
      },1500)
    },
    // 获取轮播图
    getBannerList(){
      getBannerList().then(res => {
        this.bannerList = res.data
      })
    },

    // 领取红红包过程
    displaytrue () {
      this.show1 = false
      this.show2 = false
      this.beforeShow = false
      this.afterShow = true
    },

    // 获取海报图管理
    getPlacardList(){
      getPlacardList().then(res => {
        this.posterList = res.data
      })
    },
    onPopupChange(index){
      this.currentIndex = index
    },

    // 跳转到账记录
    clickRecord(){
      this.$router.push({name:'Record'})
    },
    async getWechatCode() {
      let appid = 'wx68e55df061305e7a'         //公众号的APPID
      // let appSecret = 'af12444f6c2462eeeaa669fd1a834ccd'
      // let data = this.getUrlCode(); //是否存在code
      console.log(this.$route.query)
      let data = this.$route.query   // 获取链接参数
      if (data.state == null || data.state === "") {
        if((data.name == null || data.name == "") && data.code !=null && data.code != ""){
          window.location.href = window.location.origin+`?name=${data.code}`
          return
        }
        //不存在就打开上面的地址进行授权
        window.location.href =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
            appid +
            "&redirect_uri=" +
            encodeURIComponent(window.location.href) +
            "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
      }else {
        console.log(data)
        if(!getToken('Admin-Token')){
          let res = await wxlogin({code:data.code})
          setToken(res.token)
        }
        this.beforeShow = true
        if(data.name){
          this.obtain(data.name)
        } else {
          this.redEnvelope.hbIsReceive = 3
        }
        this.getGoodsList()
        this.getRecordsList()
        this.getBannerList()
        this.getDealList()
        this.code = data.name
      }
      // this.getPlacardList()
    },
    getUrlCode() {
      var url = new URL(window.location.href);
      var searchParams = url.searchParams;
      // 创建一个对象来存储参数
      var params = {};
      let list = []
      searchParams.forEach((value,key) => {
        params[key] = value
      })
      return params
    },
  },

};
</script>

<style lang="less" scoped>
// 看 vue.config.js
*{
  margin: 0;
  padding: 0;
}

.hong-back{
  background: #f06143;
  width: 100%;
}

.arrow-left{
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 20px 10px ;
}

.back {
  width: 100%;
  height: 160vh;
}

.before {
  position: absolute;
  bottom: 60px;
  display: flex;
  justify-content: center;
}

.wechat{
  position: absolute;
  bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wechat img{
  width: 30px;
  height: 30px;
}

.wechat p{
  color: #ffffff;
  font-size: 16px;
  margin-left:10px;
  font-weight: bold;
  font-family: 新宋体;
}

.before-img{
  width: 80%;
  height: 40%;
  position: relative;
}

.my-swipe {
  margin: 0 auto 0;
  border-radius: 15px;
  width: 360px;
  height: 180px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  padding: 0;
  text-align: center;
  background-color: #ffffff;
}
.my-swipe .van-swipe-item img {
  width: 100%;
  height: 100%;
}
.my-swipe1 .van-swipe-item {
  color: #fff;
  text-align: center;
  background-color: #ffffff;
}

.before .cash {
  //position: fixed;
  left: 0;
  right: 0;
  top: 40%;
  position: absolute;
  text-align: center;
  font-size: 62px;
  color: #ffffff;
  font-weight: 700;
  //z-index: 22;
}
.after{
  position: absolute;
  bottom: 60px;
  display: flex;
  justify-content: center;
}

.after .receive{
  position: absolute;
  width: 100%;
  top: 28%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 32;
}

.receive .title{
  font-size: 30px;
  color: #F71439;
  font-weight: 500;
}

.receive .mini-title{
  font-size: 16px;
  color: #F71439;
  font-weight: 500;
  margin-bottom: 10px;
}

.receive .amount{
  font-size: 36px;
  color: #F71439;
  font-weight: 600;
  margin-top: 30px;
}

.bg {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #00000090;
  position: fixed;
  z-index: 66;
}

.content{
  position: fixed;
  bottom: 180px;
  //top: 50%;
  //top: 200px;
  width: 100%;
  height: 60%;
}

.content .cash {
  //position: fixed;
  left: 0;
  right: 0;
  top: 40%;
  position: absolute;
  text-align: center;
  font-size: 62px;
  color: #ffffff;
  font-weight: 700;
  z-index: 22;
}

.content .img {
  width: 100%;
  height: 100%;
}

.content .receive{
  position: absolute;
  width: 100%;
  top: 28%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 22;
}


// 奖励明细
.reward{
  position: absolute;
  width: 100%;
}

.reward .amount{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.amount img{
  width: 80%;
  height: 200px;
}

.amount .am-con{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 30px;
}

.am-con .tips{
  font-size: 14px;
  color: #E13D1C;
}

.amount p{
  font-size: 24px;
  font-weight: 600;
  color: #E13D1C;
  margin-top: 20px;
}

.amount p span{
  font-size: 46px;
  font-weight: 700;
  color: #E13D1C;
}

.btn{
  background: linear-gradient(to bottom, #fef5da,#fef1ce);
  color:#e03a18;
  font-size: 16px;
  margin: 20px 0;
  padding: 10px 50px;
  border-radius: 50px;
  letter-spacing: 0.2rem;
  font-weight: 700;
  box-shadow: 0 2px 2px 2px rgba(254, 245, 218,0.4);
}

.record{
  background: #FEF2D3;
  border-radius: 30px;
  padding: 10px 0;
  width: 88%;
  margin: 30px auto;
}

.van-tabs{
  width: 90%;
  margin: 0 auto;
}

.record .item{
  display: flex;
  align-items: center;
  padding: 20px 5px ;
  border-bottom: dashed 1px #e03b19;
}
.record .item .logo{
  width: 45px;
  height: 20px;
  position: absolute;
  top: 35px;
  left: 0;
}
.record .item img{
  width:  130px;
  height: 135px;
  position: relative;
}
.record .item .item-con{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 30px;
}

.record .item2{
  display: flex;
  align-items: center;
  padding: 20px 5px ;
  border-bottom: dashed 1px #e03b19;
  position: relative;
}

.record .item2 .logo{
  width: 45px;
  height: 20px;
  position: absolute;
  top: 20px;
  right: 0;
}

.record .item2 img{
  width:  130px;
  height: 135px;
}
.record .item2 .item-con{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  margin-right: 30px;
}

.item-con span{
  color: #8D4F42;
  font-size: 16px;
  margin: 6px 0;
}

.item-con span:nth-child(1){
  font-size: 18px;
  font-weight: 600;
}

.step{
  width: 80%;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step img {
  width: 80%;
}

.step .steps-item{
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.steps-item span{
  color: #905446;
}

.box{
  padding: 15px 0;
}

.box .record-item{
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
}

.box .record-item .img{
  width: 60px;
  height: 60px;
  border-radius: 50px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FBE3A4;
}
.box .record-item .img img {
  width: 20px;
  height: 30px;
}

.box .record-item .record-content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.box .record-item .record-content span:nth-child(1){
  font-size: 18px;
  font-weight: 600;
  color: #8D4F42;
}
.box .record-item .record-content span:nth-child(2){
  font-size: 14px;
  color: #8D4F42;
  margin-top: 6px;
}

.box .record-item .cash{
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #8D4F42;
}

.rule{
  position: absolute;
  bottom: 380px;
  right: 10px;
  z-index: 66;
}

.rule img{
  width: 80px;
  height: 80px;
  transform: rotate(-20deg); /* 旋转元素 50 度 */
}

.null{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.null p{
  margin-top: 20px;
  color: #8D4F42;
}

/* 弹出层 */
.popup{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000070;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.poster-swipe{
  width: 80%;
  height: 60%;
  margin-bottom: 20px;
}

.poster-swipe .van-swipe-item img {
  width: 100%;
  height: 100%;
}
</style>
