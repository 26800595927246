<template>
  <div>
    <div class="img-bg">
      <img class="logo" src="@/assets/logo.gif">
    </div>
    <div class="text">app</div>
    <van-cell-group class="input-bg">
      <van-field v-model="account" label="账号" placeholder="请输入账号" label-width="30px" center clearable>
        <img class="icon" src="@/assets/tab/tab1.png" slot="left-icon">
      </van-field>
      <van-field v-model="pwd" label="密码" placeholder="请输入密码" type="password" label-width="30px" center clearable>
        <img class="icon" src="@/assets/tab/tab2.png" slot="left-icon">
      </van-field>
    </van-cell-group>
    <div class="base-submit-btn" style="margin-top:60px;" @click="toLogin">登 录</div>
  </div>
</template>

<script>
export default {
  name:"Login",
  components: {},
  data() {
    return {
      account: "admin",
      pwd: "yqcy123",
    };
  },
  methods: {
    toLogin() {



      // this.$toast.loading({
      //   duration: 0,
      //   message: '正在登录...',
      // });
      // this.$toast.success('登录成功');
      // this.$router.replace({ name: "Main",query:{id:1}});
      // this.$router.push({ name: "Main" ,})
      this.$router.push({ name: "Module1" ,})
      // this.$router.replace({ name: "/" });
      // login(this.account,this.pwd).then(res=>{
      //   this.$toast.success('登录成功');
      //   setToken(res.token)
      //   this.$router.replace({ name: "Main" });
      //   this.$toast.clear();
      // })
      // let appid = "1400867109"; 
      // let url = `http://192.168.2.118:8202?appid=${appid}&redirect_uri=${encodeURIComponent(
      //   location.href
      // )}&response_type=code&scope=snsapi_base&state=STATE&connect_redirect=1#wechat_redirect`;
      // this.code = this.getUrlCode().code;
      // //如果没有code 去获取code
      // if (this.code == null) {
      //   window.location.href = url;
      // } else {
      //   //获取到code后的逻辑
      //   console.log("code", this.code);
      //   // this.login();
      // }
    },
    getUrlCode() {
      var url = location.search;
      var theRequest = new Object();
      if (url.indexOf("?") !== -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
  },
  created() {
  },
};
</script>

<style>
.img-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
}

.logo {
  width: 100px;
  height: 100px;
  /* border-radius: 50px; */
  margin-top: 20px;
}

.text {
  width: 100vw;
  text-align: center;
  margin: 30px 0;
}

.input-bg {
  margin: 15px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  padding-bottom: 2px;
}
</style>
