import request from '@/utils/request'

// 首页红包弹窗  // 是否领取
export function redPacketcreate(query) {
  return request({
    url: '/user/redPacket/create',
    method: 'post',
    params: query
  })
}
// 首页红包领取
export function redPacketdrawRedPacket(query) {
  return request({
    url: '/user/redPacket/draw',
    method: 'post',
    params:query
  })
}

