<template>
  <div>
    <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar>
    <div class="lunbo">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="item in bannerList" :key="item.id">
          <img :src="item.url" alt="图"/> </van-swipe-item>
      </van-swipe>
    </div>

    <div class="box-card">
      <div class="item" @click="popupShow = true">
        <img src="../../assets/img/hai.png" alt="">
        <span>海报图</span>
      </div>
      <div class="item" @click="clickRedBad">
        <img src="../../assets/img/bad.png" alt="">
        <span>领取红包</span>
      </div>
    </div>

    <!--  产品信息  -->
    <div class="product">
      <div class="title"><p class="tips"></p>产品信息</div>
      <div class="product-card" v-for="(item,index) in goodsList" :key="index">
        <div class="product-card-item" v-if="index%2 == 0" @click="clickDetail(item.id)">
          <img :src="item.goodsUrl" alt="">
          <div class="item-con">
            <span>{{ item.goodsName }}</span>
            <span>{{ item.goodsInfo[0] }}</span>
            <span>{{ item.goodsInfo[1] }}</span>
            <span>{{ item.goodsInfo[2] }}</span>
          </div>
        </div>
        <div class="product-card-item" v-if="index%2 != 0" @click="clickDetail(item.id)">
          <div class="item-con">
            <span>{{ item.goodsName }}</span>
            <span>{{ item.goodsInfo[0] }}</span>
            <span>{{ item.goodsInfo[1] }}</span>
            <span>{{ item.goodsInfo[2] }}</span>
          </div>
          <img :src="item.goodsUrl" alt="">
        </div>
      </div>
    </div>

    <!--  海报图弹出层  -->
    <div class="popup" v-if="popupShow" @click="popupShow = false">
      <van-swipe class="poster-swipe" :autoplay="3000"
                 @change="onChange"
                 indicator-color="white">
        <van-swipe-item v-for="item in posterList" :key="item.id">
          <img :src="item.url" alt="图"/>
        </van-swipe-item>
      </van-swipe>
<!--      <van-button type="info" @click.stop="getDownloadImage">保存</van-button>-->
    </div>
  </div>
</template>

<script>
import {getBannerList,getPlacardList} from "../../api/banner";
import {getGoodsList} from "../../api/pet";
import download from 'downloadjs';

export default {
  name: "index",
  data() {
    return {
      title: '首页',
      isBack: false,
      popupShow: false,
      bannerList:[],  // 轮播图
      posterList: [
        {url:'https://xaqqht-1323926769.cos.ap-beijing.myqcloud.com/xaqqht/1710902051246.png'},
        {url:'https://xaqqht-1323926769.cos.ap-beijing.myqcloud.com/xaqqht/1710902054227.png'},
      ],  // 海报图
      goodsList: [], //商品列表
      currentIndex:0,
    }
  },
  created() {
    this.getBannerList()
    // this.getPlacardList()
    this.getGoodsList()
  },
  methods: {
    // 获取轮播图
    getBannerList(){
      getBannerList().then(res => {
        this.bannerList = res.data
      })
    },
    // 获取海报图管理
    getPlacardList(){
      getPlacardList().then(res => {
        this.posterList = res.data
      })
    },
    // 获取产品信息
    getGoodsList(){
      getGoodsList().then(res => {
        res.rows.forEach(item => {
          item.goodsInfo = item.goodsInfo.split(',')
        })
        this.goodsList = res.rows
      })
    },
    // 跳转详情
    clickDetail(id){
      this.$router.push({name:'GoodsDetail',query:{id:id}})
    },
    // 跳转红包
    clickRedBad(){
      this.$router.push({name:'Module1'})
    },
    onChange(index){
      this.currentIndex = index
    },
    // 下载图片
    getDownloadImage(){
      let url = this.bannerList[this.currentIndex].url
      const a = document.createElement('a');
      a.href = url;
      a.download = 'downloaded_image.jpg';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

  }
}
</script>

<style scoped>
.lunbo {
  /* background: #c52519; */
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
}
.my-swipe {
  margin: 0 auto 0;
  border-radius: 15px;
  width: 360px;
  height: 150px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  padding: 0;
  text-align: center;
  background-color: #ffffff;
}
.my-swipe .van-swipe-item img {
  width: 100%;
  height: 100%;
}
.my-swipe1 .van-swipe-item {
  color: #fff;
  text-align: center;
  background-color: #ffffff;
}

.box-card{
  margin:20px 10px 10px 10px;
  background: #ffffff;
  box-shadow: 0 4px 4px 4px #F8F8F8;
  display: flex;
  border-radius: 10px;
}

.box-card .item {
  width: 25%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box-card .item img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.box-card .item span{
  font-size: 14px;
}

.product{
  padding: 0 20px;
}

.product .title{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.title .tips{
  width: 5px;
  height: 14px;
  margin-right: 10px;
  border-radius: 5px;
  background: #c42b1c;
}
.product-card-item{
  width:100%;
  height: 180px;
  box-shadow: 0 4px 4px 2px #c42b1c20;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.item-con{
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item-con span{
  color: #8D4F42;
  font-size: 16px;
  margin: 6px 0;
}

.item-con span:nth-child(1){
  font-size: 18px;
  font-weight: 600;
}

.product-card-item img{
  width: 45%;
  height: 100%;
}

/* 弹出层 */
.popup{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000070;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.poster-swipe{
  width: 80%;
  height: 50%;
  margin-bottom: 20px;
}

.poster-swipe .van-swipe-item img {
  width: 100%;
  height: 100%;
}
</style>
