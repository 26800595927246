<template>
  <div>
    <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar>
    <van-cell-group title="基本设置">
      <van-cell title="到账记录" is-link @click="clickRecord" />
      <van-cell title="协议信息" is-link @click="clickProtocol" />
      <van-cell title="联系我们" is-link @click="clickContactUs" />
    </van-cell-group>

    <van-dialog v-model="show" title="联系我们" show-cancel-button>
      <p style="width: 100%;text-align: center">1566666666</p>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: "index",
  data(){
    return {
      title: '我的',
      isBack: false,
      show:false
    }
  },
  methods:{
    clickRecord(){
      this.$router.push({name:'Module1'})
    },
    // 协议信息
    clickProtocol(){
      this.$router.push({name:'Protocol'})
    },
    // 联系我们
    clickContactUs(){
      // this.$router.push({name:'ContactUs'})
      this.show = true
    }
  }
}
</script>

<style scoped>

</style>
